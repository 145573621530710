// Variable overrides
.text-completed {
    color: green;
    font-weight: bold;
}
.text-confirm {
    color: blue;
    font-weight: bold;
}
.text-cancel {
    color: red;
    font-weight: bold;
}
.text-progress {
    color: orange;
    font-weight: bold;
}