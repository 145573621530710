@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
table {

  border-collapse: separate !important;
  border-spacing: 0;
}

.table tr th div {
  font-family: "Noto Sans Medium", serif!important;
  color: rgba(24, 23, 25, 0.50)!important;
  font-size: 14px;
}

.table-title-th {
  font-family: "Noto Sans Medium", serif!important;
  color: rgba(24, 23, 25, 0.50)!important;
  font-size: 14px;
}

table tr th,
table tr td {
  vertical-align: middle !important;
  padding: 5px;
  cursor: pointer;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid rgba(24, 23, 25, 0.3);
}

table tr th:last-child,
table tr td:last-child {
  border-right: 1px solid rgba(24, 23, 25, 0.3);
}

table tr th {
  text-align: left;
  border-top: solid 1px rgba(24, 23, 25, 0.3);
}

table tr:last-child td {
  border-bottom: 1px solid rgba(24, 23, 25, 0.3);
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 12px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 12px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;

}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}


.table thead th {
  border-bottom: none;
}

.c-switch-label.c-switch-lg .c-switch-slider {
  width: 100px
}

.c-switch-label.c-switch-lg .c-switch-input:checked ~ .c-switch-slider::before {
  transform: translateX(65px);
}

.c-switch-success .c-switch-input + .c-switch-slider {
  background-color: #2eb85c;
}

.c-switch-label.c-switch-lg .c-switch-slider::after {
  color: #fff;
}

.card-columns {
  column-count: 4 !important;
}

.breadcrumb-custom {
  font-size: 24px;
  font-family: "Noto Sans Bold", serif;
  color: #181719;
  padding: 24px 0 32px 0;
}

.pl--0 {
  padding-left: 0 !important;
}

.c-sidebar-brand {
  background: #FBFCFF!important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-content {
  border-radius: 16px!important;
  border: 1px solid rgba(25, 25, 25, 0.10)!important;

}

.swal2-actions {
  flex-direction: row-reverse!important;
}
.swal2-title {
  font-size: 16px!important;
  font-family: "Noto Sans Bold", sans-serif!important;
}
.react-datepicker__monthPicker {
  width: 240px;
}

.dropdown-menu.show {
  padding: 0;
}

.badge-report {
  background: rgba(236, 78, 78, 1);
  border-radius: 12px;
  padding: 2px 8px;
  font-family: "Noto Sans Bold", sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}


input[type="checkbox"]:checked::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
     display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgba(0, 154, 255, 1);
    color: #fff;
}
input[type="checkbox"]:checked {
  border-radius: 4px;
  border: 2px solid rgba(0, 154, 255, 1)!important;
}

