@font-face {
  font-family: 'Noto Sans Black';
  src: local('Noto Sans Black'), url("./font/NotoSans-Black.ttf") format('opentype');
}

@font-face {
  font-family: 'Noto Sans Medium';
  src: local('Noto Sans Medium'), url("./font/NotoSans-Medium.ttf") format('opentype');
}

@font-face {
  font-family: 'Noto Sans Regular';
  src: local('Noto Sans Regular'), url("./font/NotoSans-Regular.ttf") format('opentype');
}

@font-face {
  font-family: 'Noto Sans Bold';
  src: local('Noto Sans Bold'), url("./font/NotoSans-Bold.ttf") format('opentype');
}

@font-face {
  font-family: 'Noto Sans Semi Bold';
  src: local('Noto Sans Semi Bold'), url("./font/NotoSans-SemiBold.ttf") format('opentype');
}

@font-face {
  font-family: 'Noto Sans Extra Bold';
  src: local('Noto Sans Extra Bold'), url("./font/NotoSans-ExtraBold.ttf") format('opentype');
}

@font-face {
  font-family: 'Inter Bold';
  src: local('Inter Bold'), url("./font/Inter-Bold.ttf") format('opentype');
}