// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@import "login";

@import "sideBar";

@import "font";

p {
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

.the-sidebar {
  background: linear-gradient(0deg, #fbfcff, #fbfcff), #ffffff;
  border-right: 1px solid rgba(24, 23, 25, 0.1);
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.avatar-custom {
  width: 100px !important;
  height: 100px !important;
}

.custom-badge {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.my-picker {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  background-color: #fff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.avatar-custom-img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  object-fit: cover;
}

.it .btn-orange {
  background-color: blue;
  border-color: #777 !important;
  color: #777;
  text-align: left;
  width: 100%;
}

.it input.form-control {
  border: none;
  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}

.it .form-control:focus {
  background: #3a4c56;
  border-color: #ff4d0d;
  box-shadow: none;
  outline: none;
}

.fileUpload {
  position: relative;
  overflow: hidden;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.sound-wave {
  width: 100%;
}

#content_ {
  width: 50%;
  background-color: #212121;
  margin: 0 auto;
}

.start-record {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 1rem 1rem 0;
  background-color: #fff;
  transition: 0.5s ease-in-out;
}

.start-record:hover {
  background-color: red;
  color: #fff;
}

.stop-record {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 0 1rem 1rem;
  background-color: #fff;
  transition: 0.5s ease-in-out;
}

.stop-record:hover {
  background-color: red;
  color: #fff;
}

#record-button {
  display: flex;
  justify-content: center;
}

.remove {
  color: blue;
  cursor: pointer;
  font-size: 18px;
}

.content-pitch {
  display: flex;
  align-items: center;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.sidebar {
  background: #3a4c56 !important;
}

//CSS loadding
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.c-header {
  border: none;
}

.c-main {
  padding-top: 0;
}

.badge-ranking {
  width: 70px;
  height: 24px;
  font-family: "Noto Sans Bold", serif;
  font-size: 12px;
  border-radius: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.normal {
  color: #565656;
  background: rgba(86, 86, 86, 0.1);
  border: 1px solid rgba(86, 86, 86, 0.2);
}

.bronze {
  color: #c36666;
  background: rgba(195, 102, 102, 0.1);
  border: 1px solid rgba(195, 102, 102, 0.2);
}

.silver {
  color: #003069;
  background: rgba(0, 48, 105, 0.1);
  border: 1px solid rgba(0, 48, 105, 0.2);
}
.gold {
  color: #b48c00;
  background: rgba(180, 140, 0, 0.1);
  border: 1px solid rgba(180, 140, 0, 0.2);
}

.background-platina {
  background: linear-gradient(
    107.03deg,
    rgba(50, 0, 156, 0.071) 25.93%,
    rgba(230, 21, 21, 0.1) 91.51%
  );
  border: 1px solid rgba(125, 56, 152, 0.2);
}

.platina {
  background: linear-gradient(
      0deg,
      rgba(25, 25, 25, 0.1),
      rgba(25, 25, 25, 0.1)
    ),
    linear-gradient(96.91deg, rgba(50, 0, 156, 0.71) 9.33%, #e61515 93.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.badge-job {
  max-width: 84px;
  height: 24px;
  padding: 4px 12px;
  column-gap: 8px;
  font-family: "Noto Sans Medium", serif;
  font-size: 12px;

  color: #181719;

  background: #f3f3f4;
  border-radius: 16px;
}

.badge-job-tool-tip {
  width: 100%;
  height: 24px;
  padding: 4px 12px;
  column-gap: 8px;
  font-family: "Noto Sans Medium", serif;
  font-size: 12px;

  color: #181719;

  background: #f3f3f4;
  border-radius: 16px;
}

.title-job {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-withdraw-red {
  width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-family: "Noto Sans Medium", serif;

  height: 32px;
  color: #d10024;
  background: #ffebf1;
  border: 1px solid #d10024;
  border-radius: 4px;
  cursor: pointer;
}

.btn-withdraw-red:hover {
  background: linear-gradient(
      0deg,
      rgba(24, 23, 25, 0.1),
      rgba(24, 23, 25, 0.1)
    ),
    #ffebf1;
}

.btn-withdraw-red:focus {
  border: 2px solid #009aff;
  border-radius: 4px;
}

.no-data {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Noto Sans Bold", serif;
  font-size: 16px;
}

.box-expert {
  display: flex;
  column-gap: 3%;
}

.content-resume {
  font-family: "Noto Sans Medium", serif;
  font-size: 12px;
  color: #181719;

  margin: 12px 0 28px 0;
  word-break: break-word;
}

.box-inquiry {
  width: 100%;
  height: 100vh;
  display: flex;

  border-top: 1px solid #d9d9d9;

  .box-left-inquiry {
    width: 50%;
    height: 100vh;
    border-right: 1px solid #d9d9d9;
    .search-div {
      padding: 16px;

      img {
        padding: 16px;
      }

      .clear-icon-filter {
        padding: 18px;
      }
    }
  }

  .box-right-inquiry {
    width: 70%;
    height: 100vh;
  }
}

.input-radio {
  display: flex;
  align-items: center;
}

.text-radio {
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: #181719;
}
.title-modal-edit-sub {
  font-family: "Noto Sans Regular", serif;
  font-size: 12px;
  color: #181719;
}

.title-modal-edit {
  font-family: "Noto Sans Extra Bold", serif;
  font-size: 16px;
  padding: 20px;
  color: #181719;
}

.radio-group {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 14px;
  row-gap: 14px;
}

.input-radio {
  width: 20px;
  height: 20px;

  &:focus {
    box-shadow: none;
  }
}

.item-radio {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.box-conversation {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, #fbfcff, #fbfcff), rgba(25, 25, 25, 0.05);
  }

  column-gap: 12px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  padding: 15px;

  .text-description_ {
    font-family: "Noto Sans Regular", serif;
    font-size: 12px;
    color: #181719;
    word-break: break-word;
  }
  .text-description__ {
    width: 500px;
    word-break: break-word;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.active-conversation {
  background: linear-gradient(0deg, #fbfcff, #fbfcff), rgba(25, 25, 25, 0.05);
}

.content-conversation {
  width: 85%;
  margin-right: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 8px;
}

.text-username {
  max-width: 64px;
  overflow: hidden;
  font-family: "Noto Sans Bold", serif;
  font-size: 16px;
  color: #181719;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.text-user-career {
  font-family: "Noto Sans Regular", serif;
  font-size: 12px;
  color: rgba(24, 23, 25, 0.8);
}
.text-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Noto Sans Regular", serif;
  font-size: 12px;
  color: rgba(24, 23, 25, 0.6);
}

.notification-conversation {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: flex-end;

  font-family: "Noto Sans Regular", serif;
  font-size: 11px;
  color: #181719;
}
.number-conversation {
  font-family: "Noto Sans Semi Bold", serif;
  font-size: 11px;
}

.day-conversation {
  width: 100%;
  height: 100vh;

  padding: 16px 12px;
  background: linear-gradient(0deg, #fbfcff, #fbfcff), #f3f3f3;

  .box-day {
    margin: 0 auto;

    width: 106px;
    height: 24px;
    background: rgba(24, 23, 25, 0.6);
    border-radius: 50px;
    padding: 4px 12px;

    font-family: "Noto Sans Semi Bold", serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }
  .my-conversation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 24px;
    .username-conversation {
      font-family: "Noto Sans Bold", serif;
      font-size: 12px;
      color: rgba(24, 23, 25, 0.5);
    }
    .text-conversation {
      max-width: 400px;
      word-break: break-word;
      font-family: "Noto Sans Regular", serif;
      color: #ffffff;
      font-size: 12px;
      line-height: 20px;

      padding: 16px;
      margin-top: 8px;

      background: #399af0;
      border-radius: 10px;
    }
    .text-conversation-img {
      width: 400px;
      word-break: break-word;
      margin-top: 8px;
    }
    .time-send {
      font-family: "Noto Sans Regular", serif;
      font-size: 12px;
      color: rgba(24, 23, 25, 0.5);

      margin-top: 12px;
    }
  }
  .customer-conversation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    margin-left: 28px;

    .username-conversation {
      font-family: "Noto Sans Bold", serif;
      font-size: 12px;
      color: rgba(24, 23, 25, 0.5);
    }
    .text-conversation {
      max-width: 400px;
      word-break: break-word;

      font-family: "Noto Sans Regular", serif;
      color: #181719;
      font-size: 12px;
      line-height: 20px;

      padding: 16px;
      margin-top: 8px;
      margin-left: 32px;

      background: rgba(25, 25, 25, 0.05);
      border-radius: 10px;
    }

    .text-system {
      text-align: center;
      padding: 2px 3px;
      margin-bottom: 15px;
      background-color: #9e9ea2;
      color: rgb(221, 219, 217);
      min-width: 100px;
      border-radius: 20px;
    }
    .time-send {
      font-family: "Noto Sans Regular", serif;
      font-size: 12px;
      color: rgba(24, 23, 25, 0.5);

      margin-top: 12px;
      margin-left: 32px;
    }
  }
}

.text-tabs {
  width: 100px;
  height: 36px;
  font-family: "Noto Sans Medium", serif;
  font-size: 12px;
  color: #8c91ae;
  cursor: pointer;
  span {
    margin-bottom: 8px;
  }
}

.active-tabs {
  font-family: "Noto Sans Bold", serif !important;
  color: #399af0 !important;
  &:after {
    content: "";
    width: 84px;
    height: 2px;
    background: #399af0;
    border-radius: 2px 2px 0px 0px;
  }
}

.result-search {
  height: 36px;
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: rgba(24, 23, 25, 0.7);

  margin-left: auto;
  span {
    margin-bottom: 8px;
  }
}

.box-search-conversation {
  height: 620px;
  overflow: auto;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.text-medium-f14 {
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: #181719;

  margin: 0;
}

.text-bold-f16 {
  font-family: "Noto Sans Bold", serif;
  font-size: 16px;
  color: #181719;

  margin: 0;
}

.no-inquiry {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: #5d5d5e;
}

.no-items-search {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  margin-top: 24px;
  padding: 22px;
  height: 620px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: rgba(24, 23, 25, 0.7);
}
.title-history {
  font-family: "Noto Sans Regular", sans-serif;
  font-size: 14px;
  color: rgba(24, 23, 25, 0.6);
  margin-bottom: 28px;
}
.text-modal-edit-span {
  color: #181719;
  font-size: 12px;
  font-family: "Inter Bold", serif;
}

.span-history {
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: #181719;
}
.span-position {
  font-family: "Noto Sans Regular", serif;
  font-size: 14px;
  color: #181719;
  opacity: 0.4;
}
.page-loading-table {
  fill: black !important;
}
