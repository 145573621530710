.c-sidebar-nav-item {
  max-width: 200px;
  margin: 0 auto;
}
.c-sidebar-nav-link {
  flex-direction: row-reverse;
  justify-content: space-between;

  color: #64748B!important;
  background: #FBFCFF!important;
  font-family: "Noto Sans Medium ", serif;
  text-decoration: none!important;
  
  
}
.c-active {
  color: #303346 !important;
  font-family: "Noto Sans Bold", serif!important;
  background: rgba(57, 154, 240, 0.1) !important;
  border-radius: 8px;
}

.c-sidebar-nav-item:last-child {
  margin-bottom: 24px;
}



.breadcrumb-item {
  &.active {
    margin: 24px 0 32px 0;
    font-family: "Noto Sans Bold", serif;
    font-size: 24px;
    color: #181719 !important;
  }
}

.clear-icon-filter {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
  z-index: 999;
}
