.card-login {
  width: 672px;
  border-radius: 32px;
}

.background-logo {
  display: flex;
  justify-content: center;
  margin-top: 11px;
}

.title-login {
  font-size: 24px;
  font-weight: 900;
  color: #181719;
  font-family: "Noto Sans Black", serif;

  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
}

.label-login {
  color: #181719;
  font-size: 12px;
  font-weight: 500;
  margin-left: 42px;
  margin-bottom: 8px;
  font-family: "Noto Sans Medium", serif;
}

.input-login {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(24, 23, 25, 0.3);
  padding: 6px 12px;
  font-size: 14px;
  font-family: "Noto Sans Medium", serif;
  color: #181719;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  z-index: 1;
}

.input-login:focus {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;

  border: 2px solid #009aff !important;
  color: #181719;
}

.input-password {
  font-size: 14px;
  font-weight: 500;
  color: #181719;
}

.input-select {
  font-size: 14px;
  font-family: "Noto Sans Medium", serif;

  border-radius: 8px;
  border: 1px solid rgba(24, 23, 25, 0.3);

  color: #181719;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 8px;

  border: 2px solid #009aff !important;
  color: #181719;
}

.py__6 {
  padding: 6px 12px;
}
.mr-12 {
  margin-right: 20px;
}

.button-login {
  width: 480px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  background: #399af0;

  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  padding: 12px 24px;
  transition: 0.5ms;
}

.button-login:hover {
  background: linear-gradient(
      0deg,
      rgba(24, 23, 25, 0.05),
      rgba(24, 23, 25, 0.05)
    ),
    #399af0;
}

.clear-icon {
  position: absolute;
  right: 50px;
  top: 14px;
  cursor: pointer;
  z-index: 999;
}

.error-message {
  font-family: "Noto Sans Bold", serif;
  color: #ff1841;
  font-size: 12px;
  margin: 0px 42px 32px 42px;
}

.error-input {
  border: 1px solid #ff1841;
  background: #ffebf1;
}

.drop-icon-select {
  position: absolute;
  right: 50px;
  top: 9px;
  cursor: pointer;
  z-index: 999;
}

.dropdown-menu.show {
  //transform: translate(12px, 47px) !important;
}

.no-items {
  display: flex;
  justify-content: center;
}

.table-no-items {
  tr th {
    border-bottom: 1px solid #d8dbe0;
  }

  tr:first-child th:first-child {
    border-bottom-left-radius: 12px;
  }

  tr:last-child th:last-child {
    border-bottom-right-radius: 12px;
  }
}

.table-responsive {
  height: 100vh;
}

.text-dropdown {
  color: #181719;
  font-size: 12px;
  font-family: "Noto Sans Medium", serif;
}

.text-dropdown-red {
  font-size: 12px;
  color: #e31c1c;
  font-family: "Noto Sans Medium", serif;
}

.dropdown-menu-custom {
  border: 1px solid rgba(25, 25, 25, 0.1);
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.btn-dropdown:focus {
  background: rgba(24, 23, 25, 0.1) !important;
  outline: none !important;
  box-shadow: none !important;
}

.box-name-user {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.nick-name-user {
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
  color: #181719;
}

.email-user {
  font-family: "Noto Sans Regular", serif;
  font-size: 12px;
  color: rgba(24, 23, 25, 0.5);
}

.badge-info_ {
  max-width: 92px;
  height: 24px;
  border-radius: 48px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3a98b4;
  color: #3a98b4;
  font-size: 12px;
  font-family: "Noto Sans Medium", serif;
  text-align: center;

  padding: 4px 12px;
}

.badge-secondary_ {
  max-width: 92px;

  height: 24px;
  padding: 4px 12px;

  border-radius: 48px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #181719;
  color: #747475;
  font-size: 12px;
  font-family: "Noto Sans Regular", serif;
  text-align: center;
}

.badge-warning_ {
  max-width: 72px;

  height: 24px;
  padding: 4px 12px;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #d95d5d;
  border-radius: 48px;
  color: #d95d5d;
  font-size: 12px;
  font-family: "Noto Sans Regular", serif;
  text-align: center;
}

.active-page {
  filter: brightness(0.1);
}

.disable-page {
  filter: opacity(0.4);
}

.box-modal-user {
  background: #fbfcff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.btn-red {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-family: "Noto Sans Medium", serif;

  height: 48px;
  color: #d10024;
  background: #ffebf1;
  border: 1px solid #d10024;
  border-radius: 4px;
  cursor: pointer;
}

.btn-blue {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-family: "Noto Sans Medium", serif;

  height: 48px;
  background: #399af0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.disable-btn {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    #399af0 !important;
}

.btn-red:hover {
  background: linear-gradient(
      0deg,
      rgba(24, 23, 25, 0.1),
      rgba(24, 23, 25, 0.1)
    ),
    #ffebf1;
}

.btn-red:focus {
  border: 2px solid #009aff;
  border-radius: 4px;
}

.btn-tranparent {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-family: "Noto Sans Medium", serif;

  height: 48px;
  color: #181719;
  cursor: pointer;
}

.btn-tranparent:hover {
  background: rgba(24, 23, 25, 0.1);
  border-radius: 4px;
}

.btn-tranparent:focus {
  background: rgba(24, 23, 25, 0.05);
  border: 1px solid rgba(24, 23, 25, 0.4);
  border-radius: 4px;
}

.title-modal {
  font-family: "Noto Sans Bold", serif;
  font-size: 16px;
  color: #d10024;

  padding: 20px;
}

.badge-red-withdraw {
  font-family: "Noto Sans Bold", serif;
  font-size: 12px;
  color: #d95d5d;
  margin-left: 40px;
  padding: 8px 16px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #d95d5d;
  border-radius: 48px;
}

.badge-info-withdraw {
  font-family: "Noto Sans Bold", serif;
  font-size: 12px;
  margin-left: 40px;
  padding: 8px 16px;
  border-radius: 48px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3a98b4;
  color: #3a98b4;
}

.badge-second-withdraw {
  font-family: "Noto Sans Bold", serif;
  font-size: 12px;
  margin-left: 40px;
  padding: 8px 16px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #181719;
  color: #747475;
  border-radius: 48px;
}

.btn-withdraw {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  background: #399af0;

  color: #ffffff;
  font-size: 12px;
  font-weight: 900;
  padding: 12px 24px;
  transition: 0.5ms;
  cursor: pointer;
}

.btn-withdraw:hover {
  background: linear-gradient(
      0deg,
      rgba(24, 23, 25, 0.05),
      rgba(24, 23, 25, 0.05)
    ),
    #399af0;
}

.box-info-user {
  display: flex;
  border: 1px solid rgba(24, 23, 25, 0.3);
  border-radius: 12px;
  padding: 24px 32px;
}

.info-user {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.span-info:nth-child(2) {
  padding-top: 8px;
  padding-bottom: 16px;
}

.span-info:nth-child(4) {
  padding-top: 8px;
  padding-bottom: 16px;
}

.span-info:nth-child(5) {
  padding-bottom: 8px;
}

.span-info:nth-child(2n + 1) {
  color: rgba(24, 23, 25, 0.6);
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
}

.span-info:nth-child(2n + 2) {
  color: #181719;
  font-family: "Noto Sans Medium", serif;
  font-size: 14px;
}

.box-history {
  display: flex;
  flex-direction: column;
  width: 40%;
  border: 1px solid rgba(24, 23, 25, 0.3);
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
}

.log-out {
  color: #64748b !important;
  background: #fbfcff !important;
  font-family: "Noto Sans Medium ", serif;
  border-top: 1px solid rgba(24, 23, 25, 0.1);

  margin: 0 24px;
  padding: 16px 16px 0 16px;

  cursor: pointer;
}

.flex-history {
  display: flex;
  align-items: center;
  column-gap: 16px;
  word-break: break-all;
}

.divider-history {
  width: 1px;
  height: 32px;
  background: rgba(57, 154, 240, 0.5);
  margin-left: 5px;
  &:last-child {
    display: none;
  }
}

.box-content {
  display: flex;
  column-gap: 24px;
}

.box-right .div-expand{
  // background-color: red !important;
}




.title-box {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: rgba(24, 23, 25, 0.05);
  }
}

.box-right {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(24, 23, 25, 0.3);
  border-radius: 12px;
  margin-top: 24px;
  padding: 3px;
  & .title-box:last-child {
    border-bottom: none !important;
  }
}

.expert-flex-end {
  display: flex;
  column-gap: 28px;
}

.dropdown-item:focus {
  background: rgba(24, 23, 25, 0.05) !important;
}

.expand-title {
  display: flex;
  justify-content: space-between;
  padding: 18px 40px;
  border-bottom: 1px solid rgba(24, 23, 25, 0.3);

  & > span {
    display: block;
    width: calc(100% / 5);
    text-align: center;
  }
}

.box-right > .div-expand:last-child .item-expand:last-child {
  border-bottom: unset;
}


.div-expand {
  background: #fbfcff;
  height: auto;
  opacity: 1;
  animation: expandAnimate 1s;
  transition: all 1s;

  & > div {
    border-bottom: 1px solid rgba(24, 23, 25, 0.3);
  }

  .item-expand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 40px;
    border-bottom: 1px solid rgba(24, 23, 25, 0.3);
   
    & > div {
      width: calc(100% / 5);
      text-align: center;
    }

    & > div:first-child .box-name-user {
      text-align: left;
    }

    & .badge-ranking {
      width: 67px;
      margin: 0 auto;
    }
  }
}

.loading-page {
  position: absolute;

  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  background: #80808a;
  opacity: 0.4;
}

.loading-item {
  position: absolute;
  top: 50%;
  right: 50%;
}

.active-expand {
  height: 0;
  opacity: 0;
  animation: collapseAnimate 0.5s;
  transition: all 0.5s;
}



@keyframes expandAnimate {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

@keyframes collapseAnimate {
  from {
    height: auto;
  }
  to {
    height: 0;
  }
}
