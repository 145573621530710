.custom-container-penalty {
  display: flex;
  justify-content: center;
}
.custom-button-penalty {
  margin: 10px;
  padding: 5px 10px;
  font-size: 1em;
}

.modal-dialog {
  width: 700px;
}
